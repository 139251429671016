import Heading from "components/Heading/Heading"
import NcImage from "shared/NcImage/NcImage"

import Audi from 'images/marques/audi.png'
import Vw from 'images/marques/vw.png'
import Mercedes from 'images/marques/mercedes.png'
import Peugeot from 'images/marques/peugeot.png'
import Toyota from 'images/marques/toyota.png'
import Renault from 'images/marques/renault.png'

const Partners = () => {
    return(
        <div>
            <Heading isCenter
            desc="Notre flotte se compose des meilleurs modèles de chaque marques."
            >Nos partenaires</Heading>
            <div className="flex flex-row flex-wrap items-center justify-between px-14">
                <NcImage src={Audi} className="w-[100px]"/>
                <NcImage src={Vw} className="w-[100px]"/>
                <NcImage src={Mercedes} className="w-[100px]"/>
                <NcImage src={Peugeot} className="w-[100px]"/>
                <NcImage src={Renault} className="w-[100px]"/>
                <NcImage src={Toyota} className="w-[100px]"/>
            </div>
        </div>
    )
}

export default Partners