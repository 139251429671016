import React, { FC } from "react";
import Heading from "components/Heading/Heading";


export interface SectionOurFeaturesProps {
  className?: string;
  type?: "type1" | "type2";
}

const FOUNDER_DEMO: any[] = [
  {
    id: "1",
    heading: "🚀 Rapidité",
    subHeading:
      "Choisissez votre véhicule et l'offre la plus adaptée à vos besoins avec un conseiller réactif et bénéficier d'une livraison express.",
  },
  {
    id: "2",
    heading: "💪 Flexibilité",
    subHeading: "Choisissez la durée de votre abonnement et changer de véhicule en fonction de vos besoins.",
  },
  {
    id: "3",
    heading: "🫰 Coûts maîtrisés",
    subHeading:
      "Un coût fixe, pas de financement, pas d'entretien. Nous respectons votre budget auto",
  },
];

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionHero2 relative flex flex-col items-center lg:flex-row lg:justify-between ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className={"px-10"}>
        <Heading isCenter desc="Leaz'it c'est un processus simple, 100% digital, accessible à tous,
          offrant une expérience d'abonnement allant de 3 à 36 mois, comme vous ne l'avez jamais connue.">
        Que vous propose Leaz'It
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
