import React, { Fragment, FC, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CarTypes } from "components/HeroSearchForm/type";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";


export interface CarQuantityDetailsProps {
  className?: string;
}

const CarQuantityDetails: FC<CarQuantityDetailsProps> = ({ className = "flex-1" }) => {


  const [citadinesInputValue, setCitadinesInputValue] = useState(0);
  const [berlinesInputValue, setBerlinesInputValue] = useState(0);
  const [suvInputValue, setSuvInputValue] = useState(0);
  const [utilitairesInputValue, setUtilitairesValue] = useState(0);
  const [vtcUtilitairesInputValue, setVtcUtilitairesInputValue] = useState(0);

  const updateLocalStorage = (type: keyof CarTypes, value:number) => localStorage.setItem(type, String(value))

  const handleChangeData = (value: number, type: keyof CarTypes) => {
    
    let newValue = {
      citadines: citadinesInputValue,
      berlines: berlinesInputValue,
      suv: suvInputValue,
      utilitaires: utilitairesInputValue,
      vtc:vtcUtilitairesInputValue

    };
    updateLocalStorage(type, value)

    if (type === "citadines") {
      setCitadinesInputValue(value);
      newValue.citadines = value;
    }
    if (type === "berlines") {
      setBerlinesInputValue(value);
      newValue.berlines = value;
    }
    if (type === "suv") {
      setSuvInputValue(value);
      newValue.suv = value;
    }
    if (type === "utilitaires") {
      setUtilitairesValue(value);
      newValue.utilitaires = value;
    }
    if (type === "vtc") {
      setVtcUtilitairesInputValue(value);
      newValue.vtc = value;
    }
  };

  const totalGuests =
    citadinesInputValue + berlinesInputValue + suvInputValue + utilitairesInputValue + vtcUtilitairesInputValue;

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none rounded-b-3xl ${
              open ? "shadow-lg" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none`}
            >
              <div className="text-neutral-400 dark:text-neutral-500 text-xl">
                <i className="las la-car-side"></i>
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} {(totalGuests > 1 ? "Véhicules" : "Véhicule")}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? `${citadinesInputValue} citadines | ${berlinesInputValue} berlines | ${suvInputValue} SUV | ${utilitairesInputValue} Utilitaires | ${vtcUtilitairesInputValue} VTC` : "Ajouter des véhicules"}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setCitadinesInputValue(0);
                    setBerlinesInputValue(0);
                    setSuvInputValue(0);
                    setUtilitairesValue(0);
                    setVtcUtilitairesInputValue(0);
                  }}
                />
              )}
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5 ">
              <NcInputNumber
                className="w-full"
                defaultValue={citadinesInputValue}
                onChange={(value) => handleChangeData(value, "citadines")}
                max={10}
                min={0}
                label="Citadines"
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={berlinesInputValue}
                onChange={(value) => handleChangeData(value, "berlines")}
                max={10}
                label="Berlines"
              />

              <NcInputNumber
                className="w-full mt-6"
                defaultValue={suvInputValue}
                onChange={(value) => handleChangeData(value, "suv")}
                max={4}
                label="SUV"
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={utilitairesInputValue}
                onChange={(value) => handleChangeData(value, "utilitaires")}
                max={4}
                label="Utilitaires"
              />


              <NcInputNumber
                className="w-full mt-6"
                defaultValue={vtcUtilitairesInputValue}
                onChange={(value) => handleChangeData(value, "vtc")}
                max={4}
                label="VTC"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default CarQuantityDetails;
