import AxiosApi from "api";
import toast from "react-hot-toast";
const DemandsApi = {

    createDemand : async function createDemand(demandeData:any, fileList:any) {
        try{
            const demand = await AxiosApi.post("/demands/", demandeData)
            await AxiosApi.post(`/demands/${demand.data.clientId}/uploads`, fileList)
            toast.success("Votre demande est déposée !")
        }catch(err){
            console.log(err)
            toast.error("Une demande existe déjà")
        }
    },
}

export default DemandsApi
