import React, { FC, ReactNode, useEffect, useState } from "react";
import { CarCardType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import CarCard2 from "components/CarCard/CarCardV2";
import CarsAvalaibleApi from "api/carsApi";
import { Link } from "react-router-dom";

//
export interface SectionGridFeaturePropertyProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeatureProperty: FC<SectionGridFeaturePropertyProps> = ({
  gridClass = "",
  heading = "Choisissez votre prochain véhicule",
  subHeading = "",
  headingIsCenter,
  tabs = ["Citadines", "Berlines", "SUV", "Utilitaires", "Pro. du transport"],
}) => {
  
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [avalaibleCars, setAvalaibleCars] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  const renderCard = (car: CarCardType, index: number) => {
    return <CarCard2 key={index} className="h-full" data={car} size="small" />;
  };
  
  const filterCars = (clicked:string) => {
    return avalaibleCars.filter((car:CarCardType) => car.car_type === clicked)
  }
  
  const [displayedCars, setDisplayedCars] = useState([])
  
  

  useEffect(() => {
    CarsAvalaibleApi.getCars()
    .then((data) => {
      setAvalaibleCars(data)
      setIsLoaded(true)
    })
  }, [])

  useEffect(() => setDisplayedCars(filterCars(activeTab)), [avalaibleCars])

  if(!isLoaded)
  {
    return <></>
  }

  return (
    <div className={`nc-SectionGridFilterCard`}>
        <HeaderFilter
          tabActive={"Citadines"}
          subHeading={subHeading}
          tabs={tabs}
          heading={heading}
          onClickTab={(item) => {
            setDisplayedCars(filterCars(item))
            setActiveTab(item)
          }}
        />
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {displayedCars.map(renderCard)}
        </div>
        <div className="flex mt-16 justify-center items-center">
          <Link to={"/flotte"}>
            <ButtonPrimary>En voir plus</ButtonPrimary>
          </Link>
        </div>
    </div>
  );
};

export default SectionGridFeatureProperty;
