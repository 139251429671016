import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import { TbPhoneFilled } from "react-icons/tb";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-row lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-3/5">
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          Pourquoi choisir le néo-leasing ?
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
        L'abonnement à un véhicule offre une solution <strong>pratique et rentable</strong>, avec des coûts maîtrisés, <strong>aucun investissement initial</strong>, 
        une gestion simplifiée, et une flexibilité dans le choix des véhicules. Idéal pour particuliers et entreprises, il évite la dépréciation,  
        <strong> réduit les risques</strong> et simplifie la gestion administrative.
        </span>
        <ButtonPrimary className="mt-6 sm:mt-11">
          <TbPhoneFilled className="mr-2"/> 01 23 45 56 78
        </ButtonPrimary>
      </div>
      <div>
        <NcImage src="https://media.discordapp.net/attachments/366582681066143754/1198957022691987516/golf8.png?ex=65c0cabd&is=65ae55bd&hm=1bef310dfa610dec378b2b3f526f08fd9e333c5ab96ff3aca4acd8f3c43171ec&=&format=webp&quality=lossless" />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
