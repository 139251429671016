import {FC, useMemo, useEffect} from 'react'
import Glide from "@glidejs/glide";
import Heading from 'components/Heading/Heading';

const UNIQUE_CLASS = "glider"

export interface HomeReviewsProps {
    itemsPerRow:number
}

const Reviews = [
    {
        id:"1",
        author:"Dominique",
        content:"Service génial ! Choix varié, inscription facile, personnel top. N'hésitez pas !"
    },
    {
        id:"2",
        author:"Mathilde",
        content:"Satisfait globalement. Véhicules bien entretenus, réservation simple. Allez-y sans réfléchir."
    },
    {
        id:"3",
        author:"Yanis",
        content:"Top ! Abonnement flexible, personnel sympa, tarifs compétitifs. Recommande vivement."
    },
    {
        id:"4",
        author:"Douha",
        content:"Concept intéressant, mais parfois des problèmes de disponibilité. Service client réactif."
    },
]

const PageHomeReviews: FC<HomeReviewsProps> = (
    {itemsPerRow}
) => {


    const renderReview = (item:any) => {
        return(
        <div className="bg-neutral-100 flex flex-col p-5 rounded-md">
            <span className='text-lg'>"{item.content}"</span>
            <span>- {item.author}</span>
        </div>
        )
    }

    let MY_GLIDEJS = useMemo(() => {
        return new Glide(`.${UNIQUE_CLASS}`, {
          perView: itemsPerRow,
          gap: 32,
          bound: true,
          breakpoints: {
            1280: {
              perView: itemsPerRow - 1,
            },
            1024: {
              gap: 20,
              perView: itemsPerRow - 1,
            },
            768: {
              gap: 20,
              perView: itemsPerRow - 2,
            },
            640: {
              gap: 20,
              perView: itemsPerRow - 3,
            },
            500: {
              gap: 20,
              perView: 1.3,
            },
          },
        });
      }, [UNIQUE_CLASS]);
    
      useEffect(() => {
        setTimeout(() => {
          MY_GLIDEJS.mount();
        }, 100);
      }, [MY_GLIDEJS, UNIQUE_CLASS]);


    return(
        <div className='lg:px-14'>
            <Heading isCenter>
                Nos clients parlent de nous
            </Heading>
            <div className="glider">
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides relative flex">
                        {Reviews.map((item, index) => (
                            <li key={item.id} className='glide__slide'>
                                {renderReview(item)}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PageHomeReviews