import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface SelectedCars {
    citadines_selected:number,
    berlines_selected:number,
    suv_selected:number,
    utilitaires_selected:number,
    vtc_selected:number
}

interface DemandState {
    is_pro: boolean
    selected_cars:SelectedCars,
    demandData:any
}

interface UpdateSelectedCarDemand {
    type:keyof SelectedCars,
    quantity:number
}

const initialState: DemandState = {
    is_pro:false,
    selected_cars:{
        citadines_selected:0,
        berlines_selected:0,
        suv_selected:0,
        utilitaires_selected:0,
        vtc_selected:0
    },
    demandData:{}

}

export const demandSlice = createSlice({
    name:"demand",
    initialState,
    reducers:{
        set_pro: (state) => {
            state.is_pro = !state.is_pro
        },
        update_selected_cars: (state, action: PayloadAction<UpdateSelectedCarDemand>) => {
            state.selected_cars[action.payload.type] = action.payload.quantity
        },
        reset_selected_cars: (state) => {
            state.selected_cars = initialState.selected_cars
        },
        set_demand_data: (state, action: PayloadAction<any>) => {
            console.log(action.payload)
            state.demandData = action.payload
        },
        set_demand_files: (state, action:PayloadAction<any>) => {
            state.demandData.files = action.payload
        }
    }
})

export const { set_pro, update_selected_cars, reset_selected_cars, set_demand_data, set_demand_files } = demandSlice.actions
export default demandSlice.reducer