import React, { FC } from "react";
import imagePng from "images/slider1.jpg";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionHero2Props {
  className?: string;
  children?: React.ReactNode;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children }) => {
  return (
    <div
      className={`nc-SectionHero2 relative ${className}`}
      data-nc-id="SectionHero2"
    >
      <div className="absolute inset-y-0 w-4/5 xl:w-4/3 right-0 flex-grow">
        <img
          className="absolute inset-0 object-cover w-full h-full"
          src={imagePng}
          alt="hero"
        />
      </div>
      <div className="relative py-14">
        <div className="relative inline-flex">
          <div className="w-screen right-20 md:right-52 inset-y-0 absolute bg-primary-300 rounded-xl"></div>
          <div className="relative max-w-3xl inline-flex flex-shrink-0 flex-col items-start py-16 sm:py-20 lg:py-24 space-y-8 sm:space-y-10 text-white">
            {children ? (
              children
            ) : (
              <>
                <h2 className="font-semibold text-4xl md:text-5xl xl:text-7xl !leading-[110%]">
                  Abonnez-vous<br />à la tranqulité
                </h2>
                <span className="text-xl md:text-lg text-500 md:max-w-2/3">
                  Découvrez <strong>le neo-leasing</strong> et recevez votre nouvelle voiture chez vous<br/>
                  À part de 399 €/mois
                </span>
                <ButtonSecondary className="btn-lg">Allons-y</ButtonSecondary>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero2;
