import React, { FC, useState } from "react";
import CommonLayout from "./CommonLayout";
import { useForm } from "react-hook-form";
import { useAppSelector } from "hooks/redux/hook";

import { validateFiles } from "components/DemandsForms/utils";
import DemandsApi from "api/demandsApi";

export interface PageAddListing7Props {}

const PageAddListing7: FC<PageAddListing7Props> = () => {
  const {
    register, 
    handleSubmit,
    getValues,
    formState,
  } = useForm({mode:"all"});

  const is_client_pro = useAppSelector((state) => state.demand.is_pro)
  let demandData = useAppSelector((state) => state.demand.demandData)
  const [newFileUploaded, setNewFileUploaded] = useState<Boolean>(false)


  const onFormSubmit = (data:any) => {
    console.log(data)
    const fData = new FormData();
    for(const [key, value] of Object.entries(data)){
      if(value instanceof FileList){

        fData.append(key, value[0])
      }
    }
    DemandsApi.createDemand(demandData, fData)
  }

  return (
    <CommonLayout
      index="07"
      backtHref="/votre-demande"
      nextBtnText="Valider votre demande"
      submitAction={handleSubmit(onFormSubmit)}
      formState={formState.isValid}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Ajoutez vos documents</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Vous êtes un {is_client_pro ? "professionnel" : "particulier"}
          </span>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <form onSubmit={handleSubmit(onFormSubmit)}>
          {is_client_pro ? 
            <div className="space-y-8">
            <div>
              <span className="text-lg font-semibold">Extrait kbis</span>
              <div className="mt-5 ">
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    {getValues('kbis') === undefined || getValues('kbis').length === 0 ? 
                    <>
                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Téléverser un fichier</span>
                          <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('kbis', {required:true, onChange:(event) => {
                              
                              setNewFileUploaded(!newFileUploaded)
                            }, validate:validateFiles})}
                          />
                        </label>
                        <p className="pl-1">ou glisser déposer ici</p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">
                        PNG, JPG, JPEG, PDF jusqu'à 10 MB
                      </p>
                    </>
                    :
                    <>
                      {<p>OK</p>}
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------- */}
            <div>
              <span className="text-lg font-semibold">CNI gérant</span>
              <div className="mt-5 ">
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                  {getValues('cni_gerant') === undefined || getValues('cni_gerant').length === 0 ? 
                    <>
                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Téléverser un fichier</span>
                          <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('cni_gerant', {required:true, onChange:(event) => {
                              setNewFileUploaded(!newFileUploaded)
                            }, validate:validateFiles})}
                          />
                        </label>
                        <p className="pl-1">ou glisser déposer ici</p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">
                        PNG, JPG, JPEG, PDF jusqu'à 10 MB
                      </p>
                    </>
                    :
                    <>
                      {<p>OK</p>}
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------- */}
            <div>
              <span className="text-lg font-semibold">Permis de conduire du gérant</span>
              <div className="mt-5 ">
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                  {getValues('permis_gerant') === undefined || getValues('permis_gerant').length === 0 ? 
                    <>
                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Téléverser un fichier</span>
                          <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('permis_gerant', {required:true, onChange:(event) => setNewFileUploaded(!newFileUploaded), validate:validateFiles})}
                          />
                        </label>
                        <p className="pl-1">ou glisser déposer ici</p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">
                        PNG, JPG, JPEG, PDF jusqu'à 10 MB
                      </p>
                    </>
                    :
                    <>
                      {<p>OK</p>}
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------- */}
            <div>
              <span className="text-lg font-semibold">Dernier bilan ou business plan</span>
              <div className="mt-5 ">
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                  {getValues('bilan_bp') === undefined || getValues('bilan_bp').length === 0 ? 
                    <>
                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Téléverser un fichier</span>
                          <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('bilan_bp', {required:true, onChange:(event) => setNewFileUploaded(!newFileUploaded), validate:validateFiles})}
                          />
                        </label>
                        <p className="pl-1">ou glisser déposer ici</p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">
                        PNG, JPG, JPEG, PDF jusqu'à 10 MB
                      </p>
                    </>
                    :
                    <>
                      {<p>OK</p>}
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------- */}
            <div>
              <span className="text-lg font-semibold">Relevé d'Identité Bancaire (RIB)</span>
              <div className="mt-5 ">
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                  {getValues('rib') === undefined || getValues('rib').length === 0 ? 
                    <>
                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Téléverser un fichier</span>
                          <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('rib', {required:true, onChange:(event) => setNewFileUploaded(!newFileUploaded), validate:validateFiles})}
                          />
                        </label>
                        <p className="pl-1">ou glisser déposer ici</p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">
                        PNG, JPG, JPEG, PDF jusqu'à 10 MB
                      </p>
                    </>
                    :
                    <>
                      {<p>OK</p>}
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
            </div>
          : 
            <div className="space-y-8">
          <div>
            <span className="text-lg font-semibold">Carte nationale d'identité (recto / verso)</span>
            <div className="mt-5 ">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  {getValues('cni') === undefined || getValues('cni').length === 0 ? 
                  <>
                    <svg
                      className="mx-auto h-12 w-12 text-neutral-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>Téléverser un fichier</span>
                        <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('cni', {onChange:(event) => {
                              setNewFileUploaded(!newFileUploaded)
                            }, validate:validateFiles})}
                          />
                      </label>
                      <p className="pl-1">ou glisser déposer ici</p>
                    </div>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                      PNG, JPG, JPEG, PDF jusqu'à 10 MB
                    </p>
                  </>
                  :
                  <>
                    {<p>OK</p>}
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* ----------------- */}
          <div>
            <span className="text-lg font-semibold">Justificatif de domicile ( -3 mois )</span>
            <div className="mt-5 ">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                {getValues('justif_domicile') === undefined || getValues('justif_domicile').length === 0 ? 
                  <>
                    <svg
                      className="mx-auto h-12 w-12 text-neutral-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>Téléverser un fichier</span>
                        <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('justif_domicile', {onChange:(event) => {
                              setNewFileUploaded(!newFileUploaded)
                            }, validate:validateFiles})}
                          />
                      </label>
                      <p className="pl-1">ou glisser déposer ici</p>
                    </div>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                      PNG, JPG, JPEG, PDF jusqu'à 10 MB
                    </p>
                  </>
                  :
                  <>
                    {<p>OK</p>}
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* ----------------- */}
          <div>
            <span className="text-lg font-semibold">Dernier bulletin de salaire ou avis d'impôts</span>
            <div className="mt-5 ">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                {getValues('bulletin') === undefined || getValues('bulletin').length === 0 ? 
                  <>
                    <svg
                      className="mx-auto h-12 w-12 text-neutral-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>Téléverser un fichier</span>
                        <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('bulletin', {onChange:(event) => {
                              setNewFileUploaded(!newFileUploaded)
                            }, validate:validateFiles})}
                          />
                      </label>
                      <p className="pl-1">ou glisser déposer ici</p>
                    </div>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                      PNG, JPG, JPEG, PDF jusqu'à 10 MB
                    </p>
                  </>
                  :
                  <>
                    {<p>OK</p>}
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* ----------------- */}
          <div>
            <span className="text-lg font-semibold">Permis de conduire</span>
            <div className="mt-5 ">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                {getValues('permis') === undefined || getValues('permis').length === 0 ? 
                  <>
                    <svg
                      className="mx-auto h-12 w-12 text-neutral-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>Téléverser un fichier</span>
                        <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('permis', {onChange:(event) => {
                              setNewFileUploaded(!newFileUploaded)
                            }, validate:validateFiles})}
                          />
                      </label>
                      <p className="pl-1">ou glisser déposer ici</p>
                    </div>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                      PNG, JPG, JPEG, PDF jusqu'à 10 MB
                    </p>
                  </>
                  :
                  <>
                    {<p>OK</p>}
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* ----------------- */}
          <div>
            <span className="text-lg font-semibold">Relevé d'Identité Bancaire (RIB)</span>
            <div className="mt-5 ">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                {getValues('rib') === undefined || getValues('rib').length === 0 ? 
                  <>
                    <svg
                      className="mx-auto h-12 w-12 text-neutral-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>Téléverser un fichier</span>
                        <input
                            id="file-upload"
                            type="file"
                            className="sr-only"
                            {... register('rib', {onChange:(event) => {
                              setNewFileUploaded(!newFileUploaded)
                            }, validate:validateFiles})}
                          />
                      </label>
                      <p className="pl-1">ou glisser déposer ici</p>
                    </div>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                      PNG, JPG, JPEG, PDF jusqu'à 10 MB
                    </p>
                  </>
                  :
                  <>
                    {<p>OK</p>}
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
            </div>
          }
        </form>
      </>
    </CommonLayout>
  );
};

export default PageAddListing7;
