import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";


export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            Abonnez-vous à la tranquilité !
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            Découvrez <strong>le neo-leasing</strong> et recevez votre nouvelle voiture chez vous<br/>
            À part de 399 €/mois
          </span>
          <ButtonPrimary>Allons-y</ButtonPrimary>
        </div>
        <div className="flex-grow">
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
      </div>
    </div>
  );
};

export default SectionHero;
