import AxiosApi from "api";


const CarsAvalaibleApi = {

    getCars : async function getCars() {
        const cars = await AxiosApi.get('cars')
        return cars.data
    },
    getCar : async function getCarById(id:number){
        try{
            const car = await AxiosApi.get(`cars/${id}`)
            return car.data
        }catch(err){
            return false
        }

    }
}

export default CarsAvalaibleApi
