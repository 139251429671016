import { useEffect} from "react";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero2 from "components/SectionHero2/SectionHero2";
import PageHomeReviews from "./PageHomeReviews";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
//
import HIW1img from "images/HIW2-1.png";
import HIW2img from "images/HIW2-2.png";
import HIW3img from "images/HIW2-3.png";
import HIW1imgDark from "images/HIW2-1-dark.png";
import HIW2imgDark from "images/HIW2-2-dark.png";
import HIW3imgDark from "images/HIW2-3-dark.png";

import SectionGridFeatureProperty from "./SectionGridFeatureProperty";

import Partners from "./PageHomePartners";

function PageHome2() {
  // CUSTOM THEME STYLE
  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;
    $body.classList.add("theme-cyan-blueGrey");
    return () => {
      $body.classList.remove("theme-cyan-blueGrey");
    };
  }, []);

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      {/* GLASSMOPHIN */}
      {/* <BgGlassmorphism /> */}

      <div className="container relative space-y-24 mb-15 lg:space-y-28 lg:mb-28">
        <SectionHero2 className="" />

        {/* SECTION2 */}
        <SectionOurFeatures type="type2"/>
        
        {/* SECTION */}
        <SectionHowItWork
          data={[
            {
              id: 1,
              img: HIW1img,
              imgDark: HIW1imgDark,
              title: "Rendez-vous",
              desc: "Cet échange rapide nous permets d'établir vos besoins afin de vous proposer une solution la plus adaptée.",
            },
            {
              id: 2,
              img: HIW2img,
              imgDark: HIW2imgDark,
              title: "Administratif",
              desc: "Vous nous communiquez vos documents afin que nous éditions le contrat de location.",
            },
            {
              id: 3,
              img: HIW3img,
              imgDark: HIW3imgDark,
              title: "Livraison du véhicule",
              desc: "Vous pouvez choisir de vous faire livrer ou de le récupérer à un point de rendez-vous. Félicitations 🎉",
            },
          ]}
        />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
            <SectionGridFeatureProperty />
        </div>
        
        {/* SECTION */}
        <div className="relative py-16">
          <SectionBecomeAnAuthor />
        </div>

        <PageHomeReviews itemsPerRow={3} />
        
        {/* SECTION */}
        <Partners />
      </div>
    </div>
  );
}

export default PageHome2;
