import React, { FC } from "react";
import { CarCardType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

import { TbUsers, TbManualGearbox } from "react-icons/tb";
import { ApiURL } from "api";

export interface CarCardProps {
  className?: string;
  data: CarCardType;
  size?: "default" | "small";
}


const CarCard2: FC<CarCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const {
    brand,
    model,
    thumbnail,
    price,
    transmission,
    seats
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-1/1 rounded-2xl overflow-hidden">
        <div className="aspect-w-16 aspect-h-9 ">
          <NcImage
            containerClassName="flex items-center justify-center"
            className="px-7"
            src={`${ApiURL}/${thumbnail}`}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-4 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h3
              className={`  capitalize ${
                size === "default"
                  ? "text-xl font-semibold"
                  : "text-lg font-semibold"
              }`}
            >
              <span className="line-clamp-1">{brand} {model}</span>
            </h3>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <TbUsers/><span className="row">{seats} places</span>
            <span>-</span>
            <TbManualGearbox/><span className="">{transmission} </span>
          </div>
          <p className="text-neutral-500 text-xs">Couleur au choix !</p>
        </div>
        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            <span className="text-base font-normal text-sm">
            À partir de
            </span>
            {` `}
            {price}
            {` `}
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                € / mois
              </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
      data-nc-id="CarCard"
    >
      <Link to={`/voiture/${data.id}`} className="flex flex-col">
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default CarCard2;
