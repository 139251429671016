import Label from "components/Label/Label";
import React, { FC, useMemo, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useForm } from "react-hook-form";
import CarQuantityDetails from "containers/ListingDetailPage/listing-car-detail/CarQuantityDetails";
import { CarCardType } from "data/types";
import CarsAvalaibleApi from "api/carsApi";

import { useAppDispatch, useAppSelector } from "hooks/redux/hook";
import { set_demand_data, set_pro } from "hooks/redux/slices/demandFormSlice";
import { useNavigate } from "react-router-dom";

export interface PageAddListing2Props {}

interface DemandInput {
  name:string
  surname:string
  address:string
  city:string 
  address_complementary:string 
  is_pro:boolean 
  company_name?:string 
  company_siren?: string 
  phone_number: string 
  email: string 
  birth_date: string
  citadines_selected:number
  berlines_selected:number
  suv_selected:number
  utilitaires_selected:number
  vtc_selected:number
  _tmp_car_selected:string
  car_selected:number
}

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const isClientPro = useAppSelector((state) => state.demand.is_pro)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoaded, setIsLoaded] = useState(false)
  const [carListingData, setCarListingData] = useState<CarCardType[]>([])
  const {
    register,
    handleSubmit,
    formState,
    getValues,
  } = useForm<DemandInput>()

  useMemo(() => {
    CarsAvalaibleApi.getCars()
    .then((data) => {
      setCarListingData(data)
      setIsLoaded(true)
    })
  }, [])

  const onSubmit = (data:DemandInput) => {
    if(!formState.isValid){ return }
    data.citadines_selected = Number(localStorage.getItem('citadines'))
    data.berlines_selected = Number(localStorage.getItem('berlines'))
    data.suv_selected = Number(localStorage.getItem('suv'))
    data.utilitaires_selected = Number(localStorage.getItem('utilitaires'))
    data.vtc_selected = Number(localStorage.getItem('vtc'))
    data.car_selected = (data.is_pro ? 0 : parseInt(data._tmp_car_selected))
    dispatch(set_demand_data(data))
  }
  if(!isLoaded){
    return <></>
  }
  return (
    <CommonLayout
      index="02"
      nextBtnText="Ajouter vos documents"
      submitAction={() => {
        onSubmit(getValues())
        navigate('/vos-documents')
      }}
      formState={formState.isValid}
      nextHref="/vos-documents"
      backtHref="/flotte"
    >
      <>
        <h2 className="text-2xl font-semibold">Compléter votre demande</h2>
        <h3 className="text-xl font-semibold" >01 Informations</h3>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-8">
            <div className="grid grid-cols-2 gap-3">
              <FormItem label="Nom">
                <Input placeholder="..." {... register("name", {required:true})}/>
              </FormItem>
              <FormItem label="Prénom">
                <Input placeholder="..." {... register("surname", {required:true})}/>
              </FormItem>
            </div>
            <FormItem label="Adresse">
              <Input placeholder="..." {... register("address", {required:true})}/>
            </FormItem>
            <div className="grid grid-cols-2 gap-3">
              <FormItem label="Ville">
                <Input placeholder="..." {... register("city", {required:true})}/>
              </FormItem>
              <FormItem label="Complément d'adresse" notRequired={true}>
                <Input placeholder="..." {... register('address_complementary')} />
              </FormItem>
            </div>
            <div className="grid grid-cols-12 items-center">
              <span className="col-span-4">Vous êtes un pro ?</span>
              <span>Oui</span>
              <Input type="checkbox" className="col-span-1 " onClick={() => dispatch(set_pro())}/>
            </div>
            {isClientPro ?
            <>
              <FormItem label="Société">
                <Input placeholder="..." {... register('company_name', {required:isClientPro})}/>
              </FormItem>
              <FormItem label="SIREN">
                <Input placeholder="..." {... register('company_siren', {required:isClientPro})}/>
              </FormItem>
              <FormItem label="Véhicule(s) souhaité(s)">
                <CarQuantityDetails className="sm"/>
              </FormItem>
            </>
            :
            <>
              <FormItem label="Date de naissance">
                <Input type="date" {... register('birth_date', {required:true})} />
              </FormItem>
              <FormItem label="Véhicule souhaité">
                <Select {... register("_tmp_car_selected", {required:!isClientPro, validate:(v)=> Number(v) !== 0})}>
                  <option selected value={0}>Sélectionner une voiture</option>
                  {carListingData.map((car) => <option key={car.id} value={car.id}>{car.brand} {car.model}</option>)}
                </Select>
              </FormItem>
            </>
            }
            <FormItem label="N° de téléphone">
              <Input placeholder="..." {... register("phone_number", {required:true, pattern: /^(?:0|\+33 ?|0?0?33 ?|)([1-9] ?(?:[0-9] ?){8})$/})} />
            </FormItem>
            <FormItem label="E-mail">
              <Input placeholder="..." type="email" {... register('email', {required:true, pattern:/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/})} />
            </FormItem>
            <div>
              <Label>Informations</Label>
              <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
                Nos conseillers vous rappelerons dans les plus brefs délais suite à ce dépôt de demande
              </span>
            </div>
          </div>
        </form>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
