import React from "react";
import MyRouter from "routers/index";
import { Provider } from "react-redux";
import { store } from "hooks/redux/store";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Provider store={store}>
      <Toaster />
        <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
          <MyRouter />
        </div>
    </Provider>
  );
}

export default App;
